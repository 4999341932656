import React, { Fragment, useState, useEffect } from "react";
import { createStructuredSelector } from 'reselect';
import { compose } from "redux";
import { connect } from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import Modal from '@material-ui/core/Modal';

import discit from '../../resources/projects/discit.json'
import filmhub from '../../resources/projects/filmhub.json'
import markov from '../../resources/projects/markov.json'
import myFood from '../../resources/projects/myFood.json'
import python from '../../resources/projects/python.json'
import shower from '../../resources/projects/shower.json'
import roulette from '../../resources/projects/roulette.json'
import youtube from '../../resources/projects/youtube.json'
import symmetry from '../../resources/projects/symmetry.json'
import umbraos from '../../resources/projects/umbraos.json'
import yum from '../../resources/projects/yum.json'
import hooks from '../../resources/projects/hooks.json'
import quilgrove from '../../resources/projects/quilgrove.json'
import cbConnect from '../../resources/projects/cb-connect.json'

import './Projects.scss';

const Projects = () => {
	const mobileBoundry = 450;
	const projects = [quilgrove, yum, umbraos, cbConnect, hooks,  markov, roulette, shower, python, youtube, discit, filmhub, myFood, symmetry];

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(window.innerWidth <= mobileBoundry ? 2 : 4);
	const [open, setOpen] = useState(false);
	const [project, setProject] = useState(null)
	const [totalPages, setTotalPages] = useState(Math.ceil(projects.length / perPage));

	const ProjectTile = ({ project, index }) => {
		return (
			<div className="projectClickable" onClick={() => handleOpen(project)}>
				<img src={`./images/projects/${project.picture}.png`} className="projectThumbnail" alt={project.name}/>
				<div className="projectThumbnailTitleWrapper">
					{
						project.banner && (<div class="ribbonProject">
							<span class={`ribbonProjectInner ${project.deprecated ? 'deprecated' : ''}`}> { project.banner.text } </span>
						</div>)
					}
					<div className="projectThumbnailTitle">{ project.name }</div>
				</div>
			</div>
		);
	}

	useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

	const getProjects = () => {
		const start = (page-1) * perPage;
		return projects.slice(start, Math.min(start + perPage, projects.length));
	};

	const handleClose = () => {
    setOpen(false);
		setProject(null);
  };

	const handleOpen = (project) => {
    setOpen(true);
		setProject(project);
  };

	const handlePageChange = (event, value) => {
    setPage(value);
  };

	const resizeListener = () => {
		if (perPage === 4 && window.innerWidth <= mobileBoundry) {
			setPerPage(2);
			setTotalPages(Math.ceil(projects.length / 2));
		} else if (perPage === 2 && window.innerWidth > mobileBoundry) {
			setPerPage(4);
			setTotalPages(Math.ceil(projects.length / 4));
		}
	};

	const getEmoji = (type) => {
		if (type === 'youtube') {
			return `🎬`; 
		}
		if (type === 'github') {
			return `💻`; 
		}
		if (type === 'game') {
			return `🎮`; 
		}
		return `🌐`; 
	}

	const ProjectBody = () => {
		if (!project) {
			return <Fragment />
		}
		return (
			<div className="projectBody"> 
				<div>
				<img src={`./images/projects/${project.picture}.png`} className="projectBodyImage" alt={project.name}/>
				</div>
				<div className="projectBodyInfo">
					<div className="projectBodyTitle"> 
						{ project.name }
					</div>
					<div className="projectBodyDescription"> 
						{ project.description }
					</div>

					{
						project.links.map((link, index) => (
							<div className="projectLink">{getEmoji(link.type)}<a href={link.link} target="_blank">{ link.text }</a></div>
						))
					}

				</div>
			</div>
		);
	};

  return (
		<Fragment>
			<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ProjectBody />
      </Modal>

			<div className="projectsContainer">
				<div className="projectsWrapper">
					{
						getProjects().map((project, index) => <ProjectTile project={project} index={index} />)
					}
				</div>
				<div className="projectsBuffer" />
				<Pagination 
					count={totalPages} 
					variant="outlined" 
					shape="rounded" 
					page={page} 
					onChange={handlePageChange}
					siblingCount={0} 
				/>
			</div>
		</Fragment>
	);
}

const mapDispatchToProps = {
};

const mapStateToProps = createStructuredSelector({
});

const hocChain = compose(
	connect(mapStateToProps, mapDispatchToProps),
);

export default hocChain(Projects);