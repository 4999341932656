import PropTypes from 'prop-types';
import { labelsShape } from '../../utils/shape'

export const types = {
	username: PropTypes.string,
	labels: labelsShape
}

export const defaultProps = {

}