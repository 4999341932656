import React, { Fragment } from "react";
import { createStructuredSelector } from 'reselect';
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl'
import { getUsername } from '../../utils/duck'
import { types, defaultProps } from './HeaderTypes.js'
import { Link } from "react-router-dom";

import './Header.scss';


const Header = ({
	intl: {
		messages: {
			projectTitle
		}
	},
	username,
}) => {
  return (
		<Fragment>
			<div className="headerContainer">
				<div className="headerHello">Hello</div>
				<div className="headerName">
					<div className="headerIam">My name is</div>
					<div className="headerSam">Sam</div>
				</div>
			</div>

			<div className="menuContainer">
				<Link to="/">
					<div className="menuButton" role="button">
						📄 About
					</div>
				</Link>

				<Link to="/hackathon">
					<div className="menuButton" role="button">
					🏆 Contests
					</div>
				</Link>

				<Link to="/projects">
					<div className="menuButton" role="button">
					🖼️ Projects
					</div>
				</Link>
			</div>
		</Fragment>
	);
}

Header.propTypes = types;
Header.defaultProps = defaultProps;

const mapStateToProps = createStructuredSelector({
	username: getUsername,
});

const hocChain = compose(
	injectIntl,
	connect(mapStateToProps),
);

export default hocChain(Header);
