import React, { Fragment } from "react";
import'./Wave.scss';

const Wave = () => {
  return (
    <div className="waveContainer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="waveTop">
        <path fill="#357EC7" fillOpacity="0.2" d="M0,224L48,202.7C96,181,192,139,288,133.3C384,128,480,160,576,165.3C672,171,768,149,864,117.3C960,85,1056,43,1152,58.7C1248,75,1344,149,1392,186.7L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="waveTop">
        <path fill="#539DC2" fillOpacity="0.1" d="M0,96L48,106.7C96,117,192,139,288,160C384,181,480,203,576,202.7C672,203,768,181,864,154.7C960,128,1056,96,1152,106.7C1248,117,1344,171,1392,197.3L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
      </svg>
 
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="waveTop">
        <path fill="#357EC7" fillOpacity="0.2" d="M0,160L48,176C96,192,192,224,288,218.7C384,213,480,171,576,160C672,149,768,171,864,154.7C960,139,1056,85,1152,69.3C1248,53,1344,75,1392,85.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="waveBottom">
				<path fill="#357EC7" fillOpacity="0.2" d="M0,256L48,266.7C96,277,192,299,288,272C384,245,480,171,576,144C672,117,768,139,864,149.3C960,160,1056,160,1152,133.3C1248,107,1344,53,1392,26.7L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
			</svg>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="waveBottom">
        <path fill="#539DC2" fillOpacity="0.1" d="M0,128L48,112C96,96,192,64,288,74.7C384,85,480,139,576,138.7C672,139,768,85,864,101.3C960,117,1056,203,1152,208C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="waveBottom">
        <path fill="#357EC7" fillOpacity="0.2" d="M0,128L48,122.7C96,117,192,107,288,128C384,149,480,203,576,234.7C672,267,768,277,864,288C960,299,1056,309,1152,288C1248,267,1344,213,1392,186.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
    </div>
	);
}

export default Wave;
