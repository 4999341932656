import React from "react";
import { Route, Switch } from "react-router-dom";

import Error from "./Error/Error";
import Main from "./Main/Main";
import Projects from "./Projects/Projects";
import Hackathon from "./Hackathon/Hackathon";

const Routes = () => {
  return (
		<Switch>
			<Route path="/" component={Main} exact />
			<Route path="/projects" component={Projects} exact />
			<Route path="/hackathon" component={Hackathon} exact />
			<Route component={Error} />
		</Switch>
	);
}

export default Routes;