import React from "react";
import { createStructuredSelector } from 'reselect';
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl'
import { } from '../../utils/duck'
import { ReactComponent as GitHubLogo } from "./github.svg";
import { ReactComponent as DevpostLogo } from "./devpost.svg";

import styles from './Main.scss';

const Main = ({
	intl
}) => {
  return (
		<div className="main">
			<img className="imgMe" src="images/me.png" />

			<div className="mainItems">
				<div className="mainItem"> 💻 Computer Scientist 🤖 AI Coder</div>
				<div className="mainItem"> 🎬 Film Enthusiast 🎮 Gamer 🐱 Cat Owner</div>
				<div className="mainItem email"> ✉️ Email: SamuelLewisHumby@gmail.com</div>
			</div>

			<a className="cvLik" href="/cv" target="_blank">
				<div className="cvContainer">
				📄 Resume
				</div>
			</a>

			<div className="linkContainer">
				<div className="linkTitle">Links</div>
				<a href="https://github.com/Samalot" target="_blank"><div className="linkItem"><GitHubLogo /> GitHub</div></a>
				<a href="https://devpost.com/Samalot?ref_content=user-portfolio&ref_feature=portfolio&ref_medium=global-nav" target="_blank"><div className="linkItem"><DevpostLogo /> Devpost</div></a>
				<a href="https://www.imdb.com/name/nm14316960/?ref_=nv_sr_srsg_0" target="_blank"><div className="linkItem"><img src="images/imdb.png" style={{height: '18px', marginRight: '5px'}}/> IMDb</div></a>
				<a href="https://leetcode.com/Samalot/" target="_blank"><div className="linkItem"><img src="images/leetcode.png" style={{height: '18px', marginRight: '5px'}}/> LeetCode</div></a>
			</div>

		</div>
	);
}

const mapDispatchToProps = {
};

const mapStateToProps = createStructuredSelector({
});

const hocChain = compose(
	injectIntl,
	connect(mapStateToProps, mapDispatchToProps),
);

export default hocChain(Main);